module.exports = {
  siteTitle: 'Nguyễn Duy Quý | Java Developer | Personal Website', // <title>
  siteUrl: 'https://quylua.com',
  siteDescription: 'Trang cá nhân Nguyễn Duy Quý',
  siteKeywords: 'Nguyễn Duy Quý, quylua98, quylua, personal website, web developer, full-stack web developer, java',
  ogTitle: 'Nguyễn Duy Quý - Java Developer',
  ogType: 'website',
  ogImage: 'https://quylua.com/images/avatar.jpg',
  ogUrl: 'https://quylua.com',
  manifestName: 'Prologue',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/favicon.png',
  authorName: 'Nguyễn Duy Quý',
  heading: ['Web Developer', 'Software Engineer'],
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/quylua98',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/quylua98',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:quylua98@gmail.com',
    },
  ],
};
